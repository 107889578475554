import { datumToString, useLiveDataMapped } from 'hooks/useLiveData';
import { BatteryConfig } from './useBatteryConfig';
import { BatteryStats } from 'app/Plant/BatteryTable';

export function useBatteryStats(batteries: BatteryConfig[]): BatteryStats[] {
  const [data] = useLiveDataMapped(batteriesToTopic(batteries));

  return batteries.map(battery => ({
    id: battery.id,
    name: battery.name,
    operatingState: data[`${battery.id}/operatingState`]?.value,
    soh: data[`${battery.id}/soh`]?.value,
    soc: data[`${battery.id}/soc`]?.value,
    dod: data[`${battery.id}/dod`]?.value,
    rte: data[`${battery.id}/rte`]?.value,
    equivalentFullCycles: data[`${battery.id}/equivalentFullCycles`]?.value,
    currentCRate: data[`${battery.id}/currentCRate`]?.value,
    availablePower: Number.isFinite(Math.abs(data[`${battery.id}/availablePower`]?.value))
      ? Math.abs(data[`${battery.id}/availablePower`]?.value)
      : undefined,
    input: data[`${battery.id}/totalEffectivePower`]?.value >= 0 ? data[`${battery.id}/totalEffectivePower`]?.value : 0,
    output: data[`${battery.id}/totalEffectivePower`]?.value < 0 ? Math.abs(data[`${battery.id}/totalEffectivePower`]?.value) : 0,
    totalEffectivePower: data[`${battery.id}/totalEffectivePower`]?.value,
    totalApparentPower: data[`${battery.id}/totalApparentPower`]?.value,
    totalReactivePower: data[`${battery.id}/totalReactivePower`]?.value,
    powerFactor: data[`${battery.id}/powerFactor`]?.value,
    frequency: data[`${battery.id}/frequency`]?.value,
    maxModuleTemp: data[`${battery.id}/maxModuleTemp`]?.value,
    minModuleTemp: data[`${battery.id}/minModuleTemp`]?.value,
    stateOfChargeMax: data[`${battery.id}/stateOfChargeMax`]?.value,
    stateOfChargeMin: data[`${battery.id}/stateOfChargeMin`]?.value,
    maxModuleVoltage: data[`${battery.id}/maxModuleVoltage`]?.value,
    minModuleVoltage: data[`${battery.id}/minModuleVoltage`]?.value,
    voltage: data[`${battery.id}/voltage`]?.value,
    voltageL1: data[`${battery.id}/voltageL1`]?.value,
    voltageL2: data[`${battery.id}/voltageL2`]?.value,
    voltageL3: data[`${battery.id}/voltageL3`]?.value,
    voltageL12: data[`${battery.id}/voltageL12`]?.value,
    voltageL23: data[`${battery.id}/voltageL23`]?.value,
    voltageL31: data[`${battery.id}/voltageL31`]?.value,
    currentL1: data[`${battery.id}/currentL1`]?.value,
    currentL2: data[`${battery.id}/currentL2`]?.value,
    currentL3: data[`${battery.id}/currentL3`]?.value,
  }));
}

function batteriesToTopic(batteries: BatteryConfig[]) {
  const measurementParams = [
    { key: 'operatingState', pvId: 'operating_state' },
    { key: 'soh', pvId: 'state_of_health' },
    { key: 'soc', pvId: 'state_of_charge' },
    { key: 'dod', pvId: 'depth_of_discharge' },
    { key: 'rte', pvId: 'round_trip_efficiency' },
    { key: 'equivalentFullCycles', pvId: 'equivalent_full_cycles' },
    { key: 'currentCRate', pvId: 'c_rate' },
    { key: 'availablePower', pvId: 'available_power' },
    { key: 'totalEffectivePower', pvId: 'total_effective_power' },
    { key: 'totalApparentPower', pvId: 'total_apparent_power' },
    { key: 'totalReactivePower', pvId: 'total_reactive_power' },
    { key: 'status', pvId: 'status' },
    { key: 'powerFactor', pvId: 'power_factor' },
    { key: 'frequency', pvId: 'frequency' },
    { key: 'maxModuleTemp', pvId: 'max_module_temp' },
    { key: 'minModuleTemp', pvId: 'min_module_temp' },
    { key: 'stateOfChargeMax', pvId: 'state_of_charge_max' },
    { key: 'stateOfChargeMin', pvId: 'state_of_charge_min' },
    { key: 'maxModuleVoltage', pvId: 'max_module_voltage' },
    { key: 'minModuleVoltage', pvId: 'min_module_voltage' },
    { key: 'voltage', pvId: 'voltage' },
    { key: 'voltageL1', pvId: 'voltage_l1' },
    { key: 'voltageL2', pvId: 'voltage_l2' },
    { key: 'voltageL3', pvId: 'voltage_l3' },
    { key: 'voltageL12', pvId: 'voltage_l12' },
    { key: 'voltageL23', pvId: 'voltage_l23' },
    { key: 'voltageL31', pvId: 'voltage_l31' },
    { key: 'currentL1', pvId: 'current_l1' },
    { key: 'currentL2', pvId: 'current_l2' },
    { key: 'currentL3', pvId: 'current_l3' },
  ];

  const topics = batteries.map(battery => {
    const batteryTopics: Record<string, string> = {};

    measurementParams.forEach(param => {
      batteryTopics[`${battery.id}/${param.key}`] = datumToString({
        source: 'measurement',
        clientId: battery.plantId,
        objectId: 'battery',
        objectIdNo: battery.id,
        pvId: param.pvId,
        pvIdNo: 0,
      });
    });

    return batteryTopics;
  });

  return Object.assign({}, ...topics);
}
