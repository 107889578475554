import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { SingleLineBESS } from '../SingleLineBESS';
import StatusCarousel from '../StatusCarousel';
import { SingleLineAlarms } from '../SingleLineAlarms';
import { AssetGauge } from 'components/Gauges/AssetGauge';
import { BatteryChargingFull, Bolt, PowerOutlined, ThermostatOutlined, VibrationOutlined, Visibility } from '@mui/icons-material';
import HexagonOverviewCanvas from './HexagonOverviewCanvas';
import { BESS } from '../SingleLineCircuit';

type SingleLineAssetProps = {
  battery: BESS;
};

const SingleLineAsset: React.FC<SingleLineAssetProps> = ({ battery }) => {
  const batteryStats = battery.batteryStats;

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={4} alignItems="start">
        <Grid item xs={12} md={3} container spacing={2} alignItems="start">
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              ESS
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SingleLineBESS bess={battery} onClick={() => false} sx={{ maxWidth: 'none', height: '100%', p: 3 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Status
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 1 }}>
              <StatusCarousel operatingState={batteryStats?.operatingState ?? 999} />
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} md={9} container spacing={2}>
          <Grid item xs={12} sm={2} md={3}>
            <AssetGauge
              value={batteryStats?.totalEffectivePower ?? 0}
              unit="kW"
              icon={<PowerOutlined fontSize="large" />}
              caption="Effective Power"
              places={0}
            />
          </Grid>

          <Grid item xs={12} sm={2} md={3}>
            <AssetGauge
              type="distributed"
              value={Math.round(batteryStats?.voltage ?? 0)}
              unit="kV"
              icon={<Bolt fontSize="large" />}
              caption="Voltage"
              places={1}
            />
          </Grid>

          <Grid item xs={12} sm={2} md={3}>
            <AssetGauge
              value={batteryStats?.totalApparentPower ?? 0}
              unit="kVA"
              icon={<Visibility fontSize="large" />}
              caption="Apparent Power"
              places={0}
            />
          </Grid>

          <Grid item xs={12} sm={2} md={3}>
            <AssetGauge
              type="distributed"
              value={batteryStats?.frequency ?? 0}
              unit="Hz"
              icon={<VibrationOutlined fontSize="large" />}
              caption="Frequency"
              places={0}
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          sx={{
            justifyContent: 'flex-end',
          }}>
          <Grid item xs={12} md={9}>
            <HexagonOverviewCanvas batteryStats={batteryStats} />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Batteries
            </Typography>
          </Grid>
          <Grid item>
            <AssetGauge
              value={batteryStats?.minModuleTemp ?? 0}
              unit="°C"
              caption="Min. Module Temp"
              icon={<ThermostatOutlined fontSize="large" />}
              places={0}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              value={batteryStats?.maxModuleTemp ?? 0}
              unit="°C"
              caption="Max. Module Temp"
              icon={<ThermostatOutlined fontSize="large" />}
              places={0}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              type="distributed"
              value={batteryStats?.stateOfChargeMin ?? 0}
              unit="%"
              caption="Min. State of Charge"
              icon={<BatteryChargingFull fontSize="large" />}
              places={0}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              type="distributed"
              value={batteryStats?.soc ?? 0}
              unit="%"
              caption="State of Charge"
              icon={<BatteryChargingFull fontSize="large" />}
              places={0}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              value={batteryStats?.stateOfChargeMax ?? 0}
              unit="%"
              caption="Max. State of Charge "
              icon={<BatteryChargingFull fontSize="large" />}
              places={0}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              value={batteryStats?.minModuleVoltage ?? 0}
              unit="V"
              caption="Min. Module Voltage"
              icon={<Bolt fontSize="large" />}
              places={0}
            />
          </Grid>

          <Grid item>
            <AssetGauge
              value={batteryStats?.maxModuleVoltage ?? 0}
              unit="V"
              caption="Max. Module Voltage"
              icon={<Bolt fontSize="large" />}
              places={0}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SingleLineAlarms clientId={battery.batteryConfig.plantId} batteryId={battery.batteryConfig.id} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleLineAsset;
