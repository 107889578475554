import { ChargingStationOutlined } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import BatteryChargingFullOutlinedIcon from '@mui/icons-material/BatteryChargingFullOutlined';
import Battery90OutlinedIcon from '@mui/icons-material/Battery90Outlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { getRatingColor } from 'helpers/getRatingColor';
import TitledPaper from 'components/TitledPaper';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { getOperatingState } from 'helpers/battery';
import SkeletonOnUndefined from '../../components/SkeletonOnUndefined';

export type BatteryStats = {
  id: number;
  name: string;
  operatingState?: number;
  soh?: number;
  soc?: number;
  dod?: number;
  rte?: number;
  equivalentFullCycles: number;
  currentCRate?: number;
  availablePower?: number;
  input?: number;
  output?: number;
  totalEffectivePower?: number;
  totalApparentPower?: number;
  totalReactivePower?: number;
  powerFactor?: number;
  frequency?: number;
  minModuleTemp?: number;
  averageModuleTemp?: number;
  maxModuleTemp?: number;
  stateOfChargeMin?: number;
  stateOfCharge?: number;
  stateOfChargeMax?: number;
  minModuleVoltage?: number;
  averageModuleVoltage?: number;
  maxModuleVoltage?: number;
  cRate?: number;
  voltage?: number;
  voltageL1?: number;
  voltageL2?: number;
  voltageL3?: number;
  voltageL12?: number;
  voltageL23?: number;
  voltageL31?: number;
  currentL1?: number;
  currentL2?: number;
  currentL3?: number;
};

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'operatingState',
    headerName: 'Operating State',
    flex: 1,
    renderCell: params => (params.value === undefined ? <Skeleton sx={{ width: '100%' }} /> : getOperatingState(params.value)),
  },
  {
    field: 'soh',
    headerName: 'SOH',
    flex: 1,
    renderCell: params => {
      const color = getRatingColor(params.value);
      return (
        <SkeletonOnUndefined value={params.value}>
          <Box
            component="span"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              color,
              alignItems: 'center',
            }}>
            <HealthAndSafetyOutlinedIcon />
            <Typography>{params.value?.toFixed(2)}%</Typography>
          </Box>
        </SkeletonOnUndefined>
      );
    },
  },
  {
    field: 'soc',
    headerName: 'SOC',
    flex: 1,
    renderCell: params => {
      return (
        <SkeletonOnUndefined value={params.value}>
          <Box sx={{ height: '100%', display: 'flex', gap: '8px', alignItems: 'center' }}>
            <BatteryChargingFullOutlinedIcon
              sx={{
                transform: 'rotate(90deg)',
              }}
            />
            <Typography>{params.value?.toFixed(2)}%</Typography>
          </Box>
        </SkeletonOnUndefined>
      );
    },
  },
  {
    field: 'dod',
    headerName: 'DOD',
    flex: 1,
    renderCell: params => {
      return (
        <SkeletonOnUndefined value={params.value}>
          <Box sx={{ height: '100%', display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Battery90OutlinedIcon
              sx={{
                transform: 'rotate(90deg)',
              }}
            />
            <Typography>{params.value?.toFixed(2)}%</Typography>
          </Box>
        </SkeletonOnUndefined>
      );
    },
  },
  {
    field: 'rte',
    headerName: 'RTE',
    flex: 1,
    renderCell: params => {
      return (
        <SkeletonOnUndefined value={params.value}>
          <Box sx={{ height: '100%', display: 'flex', gap: '8px', alignItems: 'center' }}>
            <ChangeCircleOutlinedIcon
              sx={{
                transform: 'rotate(90deg)',
              }}
            />
            <Typography>{params.value?.toFixed(2)}%</Typography>
          </Box>
        </SkeletonOnUndefined>
      );
    },
  },
  {
    field: 'equivalentFullCycles',
    headerName: 'Equivalent Full Cycles',
    flex: 1.1,
    renderCell: params => {
      return <SkeletonOnUndefined value={params.value}>{params.value?.toFixed(0)}</SkeletonOnUndefined>;
    },
  },
  {
    field: 'currentCRate',
    headerName: 'Current C-Rate',
    flex: 1,
    renderCell: params => {
      return <SkeletonOnUndefined value={params.value}>{params.value?.toFixed(1) + 'C'}</SkeletonOnUndefined>;
    },
  },
  {
    field: 'availablePower',
    headerName: 'Available Power',
    flex: 1,
    renderCell: params => {
      return <SkeletonOnUndefined value={params.value}>{params.value?.toFixed(0) + 'kW'}</SkeletonOnUndefined>;
    },
  },
  {
    field: 'input',
    headerName: 'Input',
    flex: 1,
    renderCell: params => {
      return <SkeletonOnUndefined value={params.value}>{params.value?.toFixed(0) + 'kW'}</SkeletonOnUndefined>;
    },
  },
  {
    field: 'output',
    headerName: 'Output',
    flex: 1,
    renderCell: params => {
      return <SkeletonOnUndefined value={params.value}>{params.value?.toFixed(0) + 'kW'}</SkeletonOnUndefined>;
    },
  },
];

type BatteryTableProps = {
  stats: BatteryStats[];
};

export const BatteryTable = ({ stats }: BatteryTableProps) => {
  const navigate = useNavigate();
  const onRowClick: GridEventListener<'rowClick'> = useCallback(
    params => {
      navigate(`battery/${params.row.id}`);
    },
    [navigate]
  );
  return (
    <TitledPaper
      title="BESS Units"
      icon={
        <ChargingStationOutlined
          sx={{
            transform: 'rotate(90deg)',
          }}
        />
      }>
      <DataGrid
        sx={{ border: 'none', flex: 1, cursor: 'pointer' }}
        onRowClick={onRowClick}
        columns={columns}
        rows={stats}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10]}
        autoHeight
      />
    </TitledPaper>
  );
};
